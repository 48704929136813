
const walletNewRoutes = [
  {
    path: '/walletNew',
    name: 'walletNew',
    meta: {
      cnName: '',
      usName: 'WalletNew'
    },
    component: () => import('@/views/walletNew/index.vue')
  },
  {
    path: '/revenue_detailsNew',
    name: 'revenue_detailsNew',
    meta: {
      cnName: '',
      usName: 'Revenue Details'
    },
    component: () => import('@/views/walletNew/revenue_details.vue')
  },
  {
    path: '/friend_withdrawNew',
    name: 'friend_withdrawNew',
    meta: {
      cnName: '',
      usName: 'Friend Withdraw'
    },
    component: () => import('@/views/walletNew/friend_withdraw.vue')
  },
  {
    path: '/data_reportNew',
    name: 'data_reportNew',
    meta: {
      cnName: '',
      usName: 'Data Report'
    },
    component: () => import('@/views/walletNew/data_report.vue')
  },
  {
    path: '/bill_detailNew',
    name: 'bill_detailNew',
    meta: {
      cnName: '',
      usName: 'Bill Detail'
    },
    component: () => import('@/views/walletNew/bill_detail.vue')
  },
  {
    path: '/income_detailNew',
    name: 'income_detailNew',
    meta: {
      cnName: '',
      usName: 'Income Detail'
    },
    component: () => import('@/views/walletNew/income_detail.vue')
  },
  {
    path: '/withdrawalNew',
    name: 'withdrawalNew',
    meta: {
      cnName: '',
      usName: 'Withdrawal'
    },
    component: () => import('@/views/walletNew/withdrawal.vue')
  },
  {
    path: '/withdrawal_methodNew',
    name: 'withdrawal_methodNew',
    meta: {
      cnName: '',
      usName: 'WithdrawalMethod'
    },
    component: () => import('@/views/walletNew/withdrawal_method.vue')
  },
  {
    path: '/withdrawal_method_bindNew',
    name: 'withdrawal_method_bindNew',
    meta: {
      cnName: '',
      usName: 'WithdrawalMethodBind'
    },
    component: () => import('@/views/walletNew/withdrawal_method_bind.vue')
  },
  {
    path: '/withdrawal_recordNew',
    name: 'withdrawal_recordNew',
    meta: {
      cnName: '',
      usName: 'Withdrawal Record'
    },
    component: () => import('@/views/walletNew/withdrawal_record.vue')
  },
  {
    path: '/balanceNew',
    name: 'balanceNew',
    meta: {
      cnName: '',
      usName: 'Balance'
    },
    component: () => import('@/views/walletNew/balance.vue')
  },
  {
    path: '/performanceNew',
    name: 'performanceNew',
    meta: {
      cnName: '',
      usName: 'Performance'
    },
    component: () => import('@/views/walletNew/performance.vue')
  },
  {
    path: '/anchor_recordNew',
    name: 'anchor_recordNew',
    meta: {
      cnName: '',
      usName: 'Anchor Record'
    },
    component: () => import('@/views/walletNew/anchor_record.vue')
  },
  {
    path: '/method_bind_pix',
    name: 'method_bind_pix',
    meta: {
      cnName: '',
      usName: 'Pix'
    },
    component: () => import('@/views/walletNew/method_bind_pix.vue')
  },
  {
    path: '/method_bind_sito',
    name: 'method_bind_sito',
    meta: {
      cnName: '',
      usName: 'Sito'
    },
    component: () => import('@/views/walletNew/method_bind_sito.vue')
  },
  {
    path: '/method_bind_binance',
    name: 'method_bind_binance',
    meta: {
      cnName: '',
      usName: 'Binance'
    },
    component: () => import('@/views/walletNew/method_bind_binance.vue')
  },
  {
    path: '/method_bind_bank',
    name: 'method_bind_bank',
    meta: {
      cnName: '',
      usName: 'Bank'
    },
    component: () => import('@/views/walletNew/method_bind_bank.vue')
  },
  {
    path: '/method_bind_gcash',
    name: 'method_bind_gcash',
    meta: {
      cnName: '',
      usName: 'Gcash'
    },
    component: () => import('@/views/walletNew/method_bind_gcash.vue')
  },
  {
    path: '/method_bind_usdt',
    name: 'method_bind_usdt',
    meta: {
      cnName: '',
      usName: 'USDT'
    },
    component: () => import('@/views/walletNew/method_bind_usdt.vue')
  },
  {
    path: '/method_bind_epay',
    name: 'method_bind_epay',
    meta: {
      cnName: '',
      usName: 'Epay'
    },
    component: () => import('@/views/walletNew/method_bind_epay.vue')
  }
]

export default walletNewRoutes
